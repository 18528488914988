import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import { useWorkspaces } from "../../contexts/workspacesContext";
import { containsEmailRegex, validateEmail } from "../../utils/authUtils";


export default function InviteMultiWorkspaceUsers(props) {

  const [isLoading, setIsLoading] = useState(false);

  const [emailText, setEmailText] = useState('');
  const [emails, setEmails] = useState([]);
  const [emailInputError, setEmailInputError] = useState('');

  const theme = useTheme();

  const {inviteMultipleWorkspaceUsers} = useWorkspaces();

  const handleClose = () => {
    props.onClose();
  };

  function invite() {
    if (emails.length === 0 || emails.filter(email => validateEmail(email)).length === 0) {
      setEmailInputError('No emails to import');
      return;
    }

    setIsLoading(true);

    inviteMultipleWorkspaceUsers(props.workspace.id, emails)
      .then(() => {
        props.onClose();
        props.onSuccess();
      }).catch(err => {
        console.error(err);
        setEmailInputError('Unknown error occurred: Could not send invite')
      })
      .then(() => {
        setIsLoading(false);
      });
    
  }

  function _handleTextChanged(newText) {
    setEmailText(newText);
    let stayInLoop = true;
    let tmpText = newText;
    let _emails = [];
    // console.log(tmpText);
    const MAX_LOOP = 100;
    let loopCounter = 0;
    while (stayInLoop) {
      const matches = tmpText.match(containsEmailRegex);
      if (matches) {
        _emails.push(matches[0]);
        tmpText = tmpText.replaceAll(matches[0], '');
      } else {
        stayInLoop = false;
      }
      loopCounter += 1;

      if (loopCounter > MAX_LOOP) {
        console.error('HAD TO FORCE BREAK FROM LOOP');
        stayInLoop = false;
        break;
      }
    }

    setEmails(_emails);
  }

  const buttonPluralNumberText = emails.length > 0 ? `${emails.length} ` : '';
  const buttonText = emails.length !== 1
    ? `Invite ${emails.length > 0 ? buttonPluralNumberText : ''}users`
    : `Invite ${emails[0]}`

  return (
    <Dialog PaperProps={{
      style: {
        borderRadius: 40,
        textAlign: 'center'

      }
    }} onClose={handleClose} open={props.open ? props.open : false}>
      <DialogTitle
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: 22,
          color: theme.palette.primary.darkest,
          marginRight: '1em',
          marginLeft: '1em',
        }}>
        Invite users to receive notifications
      </DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <TextareaAutosize
          placeholder={'Paste (or write) here a list of emails\n\ntest1@test.com\ntest2@test.com'}
          minRows={2}
          sx={{
            marginTop: '1em',
            
          }}
          InputProps={{
            sx: {
              borderRadius: 24,
              backgroundColor: '#eee'
            },
          }}
          style={{width: '100%', minWidth: '100%', maxWidth: '100%', minHeight: 100 }}
          fullWidth
          variant='outlined'
          label={'Email to send invite to'}
          helperText={emailInputError}
          error={emailInputError !== ''}
          onChange={e => _handleTextChanged(e.target.value)}
          value={emailText.trimStart()}
          type='text'></TextareaAutosize>
      </DialogContent>
      
      
      <DialogActions sx={{paddingTop: 0, paddingLeft: '1em', paddingRight: '1em',}}>
      <Button sx={{
          height: 48,
          borderRadius: 24,
          marginTop: '2em',
          marginBottom: '1em',
          
        }} disabled={isLoading} fullWidth variant='contained' onClick={() => { invite(); }}>{buttonText}</Button>
      </DialogActions>
    </Dialog>
  );
}
