import { Dialog, DialogContent } from "@mui/material";

function LoadingDialog(props) {
  return (
    <Dialog
      onBackdropClick={() => {}}
      disableEscapeKeyDown
      disable='true'
      PaperProps={{
        style: {
          width: 100,
          height: 100,
          borderRadius: 20,
          textAlign: 'center'
        }
      }}
      onClose={(_, reason) => { if (reason !== 'backdropClick') { props?.onClose(); }}}
      open={props.open ? props.open : false}
    >
      <DialogContent sx={{paddingBottom: 0}}>
        <i
          className='fa fa-circle-notch fa-spin'
          style={{
            WebkitAnimation: 'fa-spin 0.75s infinite linear',
            animation: 'fa-spin 0.75s infinite linear',
            marginTop: 10,
            fontSize: 40,
            height: 40,
            width: 40
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default LoadingDialog;
