import { Avatar, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import React, { createRef, useState } from "react";


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useAuth } from "../../contexts/authContext";
import { useTheme } from "@mui/styles";

export default function Profile(props) {
  const { onClose, open } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [passwordInputError, setPasswordInputError] = useState('');
  const [confirmationPasswordInputError, setConfirmationPasswordInputError] = useState('');

  const { currentUser, updatePassword, updateProfileImage } = useAuth();

  let fileInputRef = createRef();

  const theme = useTheme();


  const { currentUserProfile, profileImageUpdated } = useAuth();

  
  let _currentUserProfile = props.profileOverride
  if (props.isCurrentUser && !props.profileOverride) {
    _currentUserProfile = currentUserProfile;
  }
  const handleClose = () => {
    onClose();
  };

  function handleImageChanged(e) {
    console.log(e.target.files[0]);
    handleUpload(e.target.files[0]);
  }

  function handleUpload(image) {
    updateProfileImage(image)
      .then((url) => {
        console.log('Successfully uploaded image', url);
        profileImageUpdated(url);
      })
      .catch(err => {
        console.log('ERROR', err);
      })
      .then(() => {

      });
  };


  function save() {

    if (confirmationPassword.length > 0 && password.length === 0) {
      // no password
      setPasswordInputError('Please provide a password');
      return;
    }

    if (password.length > 0 && password !== confirmationPassword) {
      // invalid password (todo use check for strong password)
      setConfirmationPasswordInputError('Password doesn\'t match the password above');
      return;
    }

    if (password.length > 0) {
      setIsLoading(true);
      updatePassword(password)
        .then(() => {
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setPasswordInputError('Could not update password');
        })
        .then(() => {
          setIsLoading(false);
        })
    } else {
      handleClose();
    }
  }

  const isGoogleLogin = (currentUser.providerData || []).map(p => p.providerId).includes('google.com');

  return (
    <Dialog PaperProps={{
      style: {
        borderRadius: 40,
        textAlign: 'center',
        minWidth: '350pt',
      }
    }} onClose={handleClose} open={open ? open : false}>
      <DialogTitle
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: 28,
          color: theme.palette.background.darkest,

        }}>
        Profile
      </DialogTitle>
      <DialogContent>
        
        <IconButton
          style={{height: '88pt', marginBottom: '1em'}}
          onClick={() => {
            if (props.isCurrentUser) {
              fileInputRef.click();
            }
          }}>
          {(currentUser ? <Avatar sx={{height: '88pt', width: '88pt',}} src={(_currentUserProfile && _currentUserProfile.avatarURL) && _currentUserProfile.avatarURL}/> : <Avatar/>)}
          
          <input type="file" name='image' accept='image/png, image/jpeg' ref={input => fileInputRef = input} hidden onChange={handleImageChanged}/>
        </IconButton>
        <Typography sx={{ marginBottom: '1em'}} variant='h6'>{_currentUserProfile && _currentUserProfile.displayName}</Typography>
        <Typography sx={{ marginBottom: '2em'}} variant='body1'>({_currentUserProfile && _currentUserProfile.email})</Typography>
        {!isGoogleLogin && props.isCurrentUser && props.userOverride && (<div>
          <TextField
            sx={{
              marginBottom: (passwordInputError.length === 0 ? '1em' : '2em'),
            }}
            InputProps={{
              sx: {
                borderRadius: 24,
                backgroundColor: '#eee'
              },
            }}
            fullWidth
            variant='outlined'
            label={'New Password'}
            helperText={passwordInputError}
            error={passwordInputError !== ''}
            onChange={e => setPassword(e.target.value)}
            value={password}
            type='password'></TextField>
          <TextField
            sx={{
              height: 48,
            }}
            InputProps={{
              sx: {
                borderRadius: 24,
                backgroundColor: '#eee'
              },
            }}
            fullWidth
            variant='outlined'
            label={'Confirm password'}
            helperText={confirmationPasswordInputError}
            error={confirmationPasswordInputError !== ''}
            onChange={e => setConfirmationPassword(e.target.value)}
            value={confirmationPassword}
            type='password'></TextField>
        </div>)}
        
        
      </DialogContent>
      
      
      <DialogActions sx={{paddingLeft: '1em', paddingRight: '1em',}}>
      {!isGoogleLogin && (<Button sx={{
          height: 48,
          borderRadius: 24,
          marginTop: '2em',
          marginBottom: '1em',
          
        }} disabled={isLoading} fullWidth variant='contained' onClick={() => { save(); }}>Save</Button>)}
      </DialogActions>

    </Dialog>
  );
}
