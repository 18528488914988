import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { createRef, useState } from "react";
import { useWorkspaces } from "../../contexts/workspacesContext";


export default function CreateWorkspace(props) {

  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [nameInputError, setNameInputError] = useState('');
  const [imageInputError, setImageInputError] = useState('');

  const theme = useTheme();

  const {createWorkspace} = useWorkspaces();

  let fileInputRef = createRef();

  const handleClose = () => {
    props.onClose();
    cleanup();
  };

  function create() {
    setNameInputError('');
    setImageInputError('');
    if (name.length === 0) {
      setNameInputError('Name must not be empty');
      return;
    }

    if (!image) {
      setImageInputError('Please upload an icon');
      return;
    }

    setIsLoading(true);

    createWorkspace(name, file)
      .then(() => {
        props.onClose();
      }).catch(err => {
        console.error(err);
        setNameInputError('Unknown error occurred: Could not create workspace')
      })
      .then(() => {
        setIsLoading(false);
      });
    
  }

  function cleanup() {
    setIsLoading(false);
    setName('')
    setImage(null);
    setFile(null);
    setImageInputError('');
    setNameInputError('');
    URL.revokeObjectURL(image);
    fileInputRef.current.value = null;
  }

  function handleImageChanged(e) {
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]));
  }

  const avatarBorder = imageInputError.length > 0
    ? `1px solid ${theme.palette.error.light}`
    : null;

  return (
    <Dialog PaperProps={{
      style: {
        borderRadius: 40,
        textAlign: 'center'

      }
    }} onClose={handleClose} open={props.open ? props.open : false}>
      <DialogTitle
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: 28,
          color: theme.palette.background.darkest,
          marginRight: '1em',
          marginLeft: '1em',
        }}>
        Create a new workspace
      </DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>

        <div style={{marginTop: '8pt', marginBottom: '1em'}}>
          <IconButton
            style={{
              height: '88pt', marginBottom: '8pt'
            }}
            onClick={() => {
              fileInputRef.click();
            }}>
            <Avatar sx={{
              border: avatarBorder,
              height: '88pt',
              width: '88pt'}} src={image}/>
            <input type="file" name='image' accept='image/png, image/jpeg' ref={input => fileInputRef = input} hidden onChange={handleImageChanged}/>
          </IconButton>
          <Typography color={theme.palette.error.main}>{imageInputError}</Typography>
        </div>
        
        <TextField
          sx={{
            marginTop: '1em',
          }}
          InputProps={{
            sx: {
              borderRadius: 24,
              backgroundColor: '#eee'
            },
          }}
          fullWidth
          variant='outlined'
          label={'Workspace name'}
          helperText={nameInputError}
          error={nameInputError !== ''}
          onChange={e => setName(e.target.value)}
          value={name}
          type='text'></TextField>
      </DialogContent>
      
      
      <DialogActions sx={{paddingTop: 0, paddingLeft: '1em', paddingRight: '1em',}}>
        <Button
          sx={{
            height: 48,
            borderRadius: 24,
            marginTop: '2em',
            marginBottom: '1em',  
          }} disabled={isLoading} fullWidth variant='contained' onClick={() => { create(); }}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}
