import React, {useState} from "react";
import brandingImage from '../../images/branding.png';
import { AppBar, Avatar, Box, IconButton, Toolbar, Typography } from '@mui/material';

import { useAuth } from "../../contexts/authContext";
import { useTheme } from "@mui/material";

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { useNavigate } from "react-router";
import Profile from "../Profile";

// ------------


import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';


export default function TopBar(props) {

  const {currentUser, currentUserProfile, signOut,} = useAuth();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [profileOpen, setProfileOpen] = useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileOpen = () => {
    setProfileOpen(true);
  };

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  const navigate = useNavigate();

  const handleSelectedWorkspaceChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(event);
    
    navigate(`/workspace/${value}`);

    props.setSelectedWorkspace(props.myWorkspaces.filter(w => w.id === value)[0]);

  };

  const MenuProps = {
    PaperProps: {
      style: {
        borderRadius: '20pt',
        maxHeight: '200pt',
        
      },
    },
  };

  return (
    <div>
      {currentUser && (<Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={0} color='transparent' position="static">
        <Profile isCurrentUser open={profileOpen ? profileOpen : false} onClose={handleProfileClose}></Profile>
        <Toolbar>
          <IconButton disableRipple onClick={() => { navigate('/') }}>
            <img width={44} height={207} style={{height: 44, width: 207}} src={brandingImage} className="AppIcon-logo" alt="logo" />
          </IconButton>
          
          {(props.selectedWorkspace && props.myWorkspaces.length > 0) && (
            <Select
              sx={{
                borderRadius: 22,
                height: 44,
                paddingLeft: '0.5em',
                paddingRight: '0.5em',
              }}
              displayEmpty
              value={props.selectedWorkspace.id}
              onChange={handleSelectedWorkspaceChange}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {props.myWorkspaces.map(w => (
                <MenuItem value={w.id} key={w.id}>
                  {w.name}
                </MenuItem>
              ))}
            </Select>
          )}
          <Typography variant="h6" component="div" sx={{ color: theme.palette.primary.darkest, paddingLeft: '1em', textAlign: 'left', flexGrow: 1 }}>
          </Typography>

          <Typography variant="h6" component="div" sx={{ color: theme.palette.primary.darkest, paddingLeft: '1em', textAlign: 'left'}}>
             {currentUserProfile && currentUserProfile.displayName}
          </Typography>
          {currentUser && (
            <div>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
            >
              {/* <AccountCircle /> */}
              <Avatar sx={{ height: 44, width: 44 }} src={(currentUserProfile && currentUserProfile.avatarURL) ? currentUserProfile.avatarURL : ''}/>
            </IconButton>
            <Menu
              PaperProps={{style: {borderRadius: 20}}}
              sx={{ mt: '45px'}}
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => {
                handleClose();
                handleProfileOpen();
              }}>Profile</MenuItem>
              <MenuItem onClick={() => {
                handleClose();
                signOut();
                }}>Sign out</MenuItem>
            </Menu>
          </div>)}
        </Toolbar>
      </AppBar>
    </Box>)}
    </div>
  )
}