import { ModeEdit, Photo } from '@mui/icons-material';
import { Avatar, Button, IconButton, Paper, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useWorkspaces } from '../../contexts/workspacesContext';
import CopyToClipboardText from '../CopyToClipboardText';
import LoadingDialog from '../Loader/loadingDialog';
import CodeSample from './codeSample';

// function useDidUpdateEffect(fn, inputs) {
//   const didMountRef = useRef(false);

//   useEffect(() => {
//     if (didMountRef.current) { 
//       return fn();
//     }
//     didMountRef.current = true;
//   }, inputs);
// }

let _settingsDebounceTimeout;
function debounce(callback, limit) {
  
                     
  return function () {                     
    if (_settingsDebounceTimeout) {
      clearTimeout(_settingsDebounceTimeout);
    }
    _settingsDebounceTimeout = setTimeout(function () {
      callback();
    }, limit);
    
  }
}


export default function Settings(props) {
  const {workspace} = props;
  const {getWorkspaceImageURL, uploadWorkspaceImage, updateWorkspaceName, onWorkspaceKeys, addAPIKey} = useWorkspaces();

  const [imageInputError, setImageInputError] = useState('');

  const initialName = workspace.name;
  const [name, setName] = useState(initialName);
  const [file, setFile] = useState(null);
  
  const [image, setImage] = useState(getWorkspaceImageURL(workspace.id));

  const [apiKeys, setAPIKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let fileInputRef = createRef();

  const theme = useTheme();

  useEffect(() => {
    if (!file) {
      return;
    }
    setIsLoading(true);
    return uploadWorkspaceImage(workspace.id, file)
      .then(() => {
        
      })
      .catch((err) => {console.error(err)})
      .then(() => {
        cleanup();
      });
  }, [file]);

  const prevNow = new Date();
  useEffect(() => {
    setIsLoading(true);
    setName(workspace.name);
    setImage(getWorkspaceImageURL(workspace.id));
    return onWorkspaceKeys(workspace.id, _apiKeys => {
      setAPIKeys(_apiKeys);

      let waitTime = 0;
      const requestDuration = (new Date()).getTime() - prevNow.getTime();
      if (requestDuration < 500) {
        waitTime = 500 - requestDuration;
      }
      setTimeout(() => {
        setIsLoading(false);
      }, waitTime);
    })
  }, [workspace]);

  function cleanup() {
    setIsLoading(false);
    setFile(null);
    setImageInputError('');
    const newUrl = `${getWorkspaceImageURL(workspace.id)}&reloadHash=${uuidv4()}`;
    console.log('NEW URL', newUrl);
    setImage(newUrl);
  }

  function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  function handleImageChanged(e) {
    console.log('NEW IMG made it');

    setFile(e.target.files[0]);
  }

  const updateName = debounce(() => {
    console.log('Setting name', name);
    updateWorkspaceName(workspace.id, name);
  }, 100);

  function onNameChange(e) {
    const _name = e.target.value;
    setName(_name);
  }

  const avatarBorder = imageInputError.length > 0
    ? `1px solid ${theme.palette.error.light}`
    : null;

  const navigate = useNavigate();

  return (
    <div style={{padding: '2em'}}>
      <LoadingDialog open={isLoading} onClose={() => { }} />
      <Paper align='start' sx={{ position: 'relative', borderRadius: 10, padding: '2em', paddingTop: '1em', paddingLeft: '3em', paddingBottom: '4em', marginBottom: '1em'}}>
        <Typography sx={{marginBottom: '2em', marginTop: '0.5em'}} variant='h4' align='left'>Workspace Info</Typography>
        <div style={{position: 'relative', width: '60pt', height: '60pt', display: 'inline-block', marginRight: '2em',}}>
          <IconButton
            style={{
              width: '60pt',
              height: '60pt',
            }}
            onClick={() => {
              fileInputRef.click();
            }}>
            <Avatar sx={{
              borderRadius: 4,
              border: avatarBorder,
              height: '60pt',
              width: '60pt'}} src={image}>
                <Photo sx={{height: '44pt', width: '44pt'}}/>
            </Avatar>
            <input type="file" name='image' accept='image/png, image/jpeg' ref={input => fileInputRef = input} hidden onChange={handleImageChanged}/>
          </IconButton>
          <ModeEdit color='white' sx={{padding: '4px', borderRadius: 10, backgroundColor: theme.palette.primary.main, position: 'absolute', top: '-4pt', right: '-4pt', width: 20, height: 20}}/>
        </div>
        <div style={{marginTop: '2em', display: 'flex', justifyContent: 'left', verticalAlign: 'center', alignItems: 'flex-start', flexDirection: 'row'}}>
          <TextField
            sx={{
              display: 'inline-block',
              minWidth: '270pt',
            }}
            InputProps={{
              sx: {
                minWidth: '270pt',
                paddingLeft: '1em',
                borderRadius: 24,
                backgroundColor: '#eee'
              },
            }}
            // fullWidth
            variant='outlined'
            helperText={''}
            error={false}
            onChange={onNameChange}
            value={name}
            type='text'></TextField>
          <Button sx={{
              marginLeft: '1em',
              display: 'inline-block',
              width: 100,
              height: 48,
              borderRadius: 24,
              
            }} disabled={isLoading} variant='contained' onClick={() => { updateName(); }}>Save</Button>
        </div>
        
        <Typography sx={{marginBottom: '1em', marginTop: '2em'}} variant='h4'>Developer Settings</Typography>
        <Typography sx={{marginBottom: '2em'}} variant='body1'>
          It's possible to send invites and Toots(!) directly from your own application:
        </Typography>
        <CopyToClipboardText title='Workspace ID' text={workspace.id} />
        {(apiKeys.length === 0 && !isLoading) && (
          <div style={{padding: '1em'}}>
            <Button sx={{
              height: 48,
              borderRadius: 24,
              marginTop: '2em',
              marginBottom: '1em',
              
            }} disabled={isLoading} variant='contained' onClick={() => { addAPIKey(workspace.id) }}>Generate API Key</Button>
          </div>
        )}
        {apiKeys.map((apiKey, i) => (
          <CopyToClipboardText
            maskText
            title={i !== 0 ? `API Key ${i + 1}` : 'API Key'}
            text={apiKey} />
        ))}

        <Typography sx={{marginTop: '2em', marginBottom: '1em'}} variant='h5'>
          Example Usage
        </Typography>        
        {apiKeys.length ? (<div style={{marginTop: '1em'}}>
          <Typography sx={{marginBottom: '2em'}} variant='h6'>
            Sending invites:
          </Typography>
          <CodeSample workspaceID={workspace.id} apiKey={apiKeys[0]} variant='invite' language='js'/>
        </div>) : (<></>)}

        {apiKeys.length ? (<div style={{marginTop: '1em'}}>
          <Typography sx={{marginBottom: '2em'}} variant='h6'>
            Sending Toots(!):
          </Typography>
          <CodeSample workspaceID={workspace.id} apiKey={apiKeys[0]} variant='toot' language='js'/>
        </div>) : <></>}
        <Button
          sx={{
            height: 48,
            borderRadius: 24,
            marginTop: '2em',
            marginBottom: '1em',    
          }} disabled={isLoading} variant='contained' onClick={() => { navigate('/docs')}}>Full API Documentation</Button>
      </Paper>
    </div>
  )
}
