import { Avatar, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { useState } from 'react';
import InviteDialog from '../InviteDialog';
import Profile from '../Profile';

export default function Collaborators(props) {
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState();

  const {
    workspace,
    invites,
    setInviteDialogOpen,
    inviteDialogOpen,
  } = props
  return (<div>
    <List sx={{margin: '2em', marginLeft: '2em'}}>
    {Object.keys(workspace.userProfiles).map(id => (
        <ListItem
          onClick={() => {
            setSelectedProfile(workspace.userProfiles[id]);
            setProfileDialogOpen(true);
          }} disableRipple sx={{ margin: '1em', height: '88pt', borderRadius: '44pt', border: '1px solid #f0f0f0'}} button key={id}>
          <ListItemIcon>
            <Avatar sx={{ marginLeft: '2em', marginRight: '2em', height: '60pt', width: '60pt'}} key={`avatar-${workspace.id}-${id}`} src={workspace.userProfiles[id].avatarURL || ''}></Avatar>
          </ListItemIcon>
          <ListItemText primary={workspace.userProfiles[id].displayName}/>
        </ListItem>
      ))}
      {invites.length > 0 && (<Divider>
        <Typography sx={{paddingLeft: '1em', paddingRight: '1em'}} variant='h6'>Invited</Typography>
      </Divider>)}
      
      {invites.map(invite => (
        <ListItem disableRipple sx={{ margin: '1em', height: '88pt', borderRadius: '44pt', border: '1px solid #f0f0f0'}} button key={invite.id}>
          <ListItemIcon>
          <Avatar sx={{ marginLeft: '2em', marginRight: '2em', height: '60pt', width: '60pt'}} key={`avatar-${workspace.id}-${invite.id}`}/>
          </ListItemIcon>
        <ListItemText primary={invite.email} />
      </ListItem>
      ))}
    </List>

    {workspace && (<InviteDialog workspace={workspace} onClose={() => { setInviteDialogOpen(false); }} open={inviteDialogOpen}></InviteDialog>)}
    <Profile
      isCurrentUser={false}
      profileOverride={selectedProfile}
      open={profileDialogOpen}
      onClose={() => {
        setProfileDialogOpen(false);
      }} /> 
  </div>);
} 