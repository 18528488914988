import { doc, getDoc, onSnapshot } from '@firebase/firestore';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import TermsDialog from '../components/TermsDialog/termsDialog';
import { auth, storageRef, upload, updatePassword as firUpdatePassword, database } from '../utils/firebase';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfile, setCurrentUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams.get('t');
  const inviteEmail = searchParams.get('email');

  const fullLocation = window.location.href;
  
  if (fullLocation.includes('/workspace/')) {
    const wid = window.location.pathname.replace('/workspace/', '');
    if (inviteToken) {
      localStorage.setItem('inviteWorkspace', wid);
      localStorage.setItem('inviteToken', inviteToken);
      localStorage.setItem('inviteEmail', inviteEmail);
    }
  }

  function getProfileImageURL(userID) {
    return `https://firebasestorage.googleapis.com/v0/b/multitoot-71ca4.appspot.com/o/profile_images%2F${userID}?alt=media`
  }

  function profileImageUpdated(url) {
    setCurrentUserProfile({
      ...currentUserProfile,
      avatarURL: url,
    });
  }

  function onUserProfile(uid, callback) {
    const ref = doc(database(), 'user_profiles', uid);
    onSnapshot(ref, async snap => {
      if (snap.exists()) {
      
        const data = snap.data();
        
  
        const avatarURL = getProfileImageURL(uid);
  
        callback({
          ...data,
          avatarURL,
        });
      } else {
        console.log(`User profile doesn't exist for ${currentUser && uid === currentUser.uid ? 'logged in user' : uid}`);
        callback(null);
      }
    })
  }

  async function getUserProfile(uid) {
    const snap = await getDoc(doc(database(), 'user_profiles', uid));
    if (snap.exists()) {
      
      const data = snap.data();
      const profileImageRef = storageRef(`profile_images/${uid}`);

      const avatarURL = getProfileImageURL(uid);

      return {
        ...data,
        avatarURL,
      }
    } else {
      return Promise.reject(Error(`User profile doesn't exist: ${uid}`));
    }
  }

  let userProfileObserver;

  useEffect(() => {
    const _auth = auth();
    const authObserver = _auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
        setIsLoading(false);

        userProfileObserver = onUserProfile(user.uid, userProfile => {
          setCurrentUserProfile(userProfile);
        });
      } else {
        setCurrentUser(null);
        setCurrentUserProfile(null);
        setIsLoading(false);
      }
      
      
    });
    
    return () => {
      authObserver();
      if (userProfileObserver) {
        userProfileObserver();
      }
    } 
  }, []);

  async function signup(email, password) {
    const _auth = auth();
    return createUserWithEmailAndPassword(_auth, email, password);
  }

  async function login(email, password) {
    const _auth = auth();
    return signInWithEmailAndPassword(_auth, email, password);
  }

  async function resetPassword(email) {
    const _auth = auth();
    return sendPasswordResetEmail(_auth, email);
  }

  async function updateEmail(newEmail) {
    return currentUser.updateEmail(newEmail);
  }

  async function updatePassword(newPassword) {
    return firUpdatePassword(currentUser, newPassword);
  }

  async function updateProfileImage(image, onProgress) {
    const ref = storageRef(`profile_images/${currentUser.uid}`)
    const uploadTask = upload(ref, image);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          onProgress && onProgress(progress);
        },
        error => {
          reject(error);
        },
        () => {
          resolve(getProfileImageURL(currentUser.uid));
        }
      );
    });
    
    
  }

  function signOut() {
    const _auth = auth();
    _auth.signOut();
  }
  
  const value = {
    currentUser,
    currentUserProfile,
    login,
    signup,
    resetPassword,
    signOut,
    updateEmail,
    updatePassword,
    updateProfileImage,
    getUserProfile,
    profileImageUpdated,
  };

  const showTerms = currentUserProfile && !currentUserProfile.termsAccepted;
  return (<AuthContext.Provider value={value}>
    {!isLoading && children}
    <TermsDialog uid={currentUser?.uid} open={showTerms}></TermsDialog>
  </AuthContext.Provider>);
}