import { Avatar, Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router";

export default function WorkspaceCard(props) {
  const {workspace} = props;
  const w = workspace;

  const navigate = useNavigate();
  const theme = useTheme();

  const contributorCount = Object.keys(workspace.roles).length;
  let contributorText = `${contributorCount} contributors`;
  if (contributorCount === 1) {
    contributorText = 'One contributor';
  } 


  return (<Card onClick={() => { navigate(`/workspace/${w.id}`)}} sx={{
    cursor: 'pointer',
    backgroundColor: '#fefefe',
    color: theme.palette.primary.darkest,
    borderRadius: 12,
    padding: '2em',
    minWidth: '200pt',
    maxHeight: '130pt',
  
    marginRight: '1em',
    marginBottom: '1em',
    '&:hover': {
      opacity: 0.85
    },
  }}>
  
  <CardContent>
  <Avatar sx={{height: '60pt', width: '60pt', margin: '0 auto', marginBottom: '16pt'}} src={w.avatarURL}></Avatar>
  <Typography variant='h5'>{w.name && w.name}</Typography>
    {/* <Typography sx={{ textAlign: 'left', marginRight: '1em', marginBottom: '0.25em'}} variant='body1'>- No ongoing campaigns</Typography> */}
    {/* <Typography sx={{ textAlign: 'center', marginRight: '1em', marginBottom: '0.25em'}} variant='body1'>{contributorText}</Typography>
    <CardMedia>
      <AvatarGroup sx={{
        margin: '0 auto',
        width: Object.keys(w.userProfiles).length * 44,
        maxWidth: 44 * 5,
      }}>
          {Object.keys(w.userProfiles).map(id => (
            <Avatar key={`avatar-${w.id}-${id}`} src={w.userProfiles[id] ? (w.userProfiles[id].avatarURL || '') : ''}></Avatar>
          ))}
      </AvatarGroup>
    </CardMedia> */}
    
  </CardContent>
  
  
    
  
  

    
</Card>)
}