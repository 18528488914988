import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useWorkspaces } from "../../contexts/workspacesContext";

import Page from "../Page";

import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AddRounded } from "@mui/icons-material";
import Dashboard from "./dashboard";
import Collaborators from "./collaborators";
import Usage from "./usage";
import Settings from "./settings";

export default function Workspace(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [workspace, setWorkspace] = useState({id: '-1'});
  const [invites, setInvites] = useState([]);

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  
  const { onUserWorkspaces, acceptInvite, onWorkspaceInvites } = useWorkspaces();

  const id = window.location.pathname.replace('/workspace/', '');

  const navigate = useNavigate();

  const location = useLocation();

  let cancelWorkspaceListener;

  useEffect(() => {
    return () => {
      if (cancelWorkspaceListener) {
        console.log('cancelling, ')
        cancelWorkspaceListener();
      }
    }
  }, []);

  useEffect(() => {

    let acceptInvitePromise = Promise.resolve();

    let isAcceptingInvite = false;
    if (localStorage.getItem('inviteWorkspace')) {
      isAcceptingInvite = true;
      acceptInvitePromise = acceptInvite(localStorage.getItem('inviteWorkspace'))
    }

    acceptInvitePromise
      .then(() => {
        cancelWorkspaceListener = onUserWorkspaces(wsps => {
          
          
          const filtered = wsps.filter(w => w.id === id);
          if (filtered.length === 0) {
            if (isAcceptingInvite) {
              setWorkspace({id: '-1'});
              return;
            }
            navigate('/');
            return;
          }
          const theWorkspace = filtered[0];
          props.setSelectedWorkspace(theWorkspace);
          props.setMyWorkspaces(wsps);
          setWorkspace(theWorkspace);
          if (selectedTab.includes('Dashboard')) {
            setSelectedTab(`${workspace && workspace.name ? `${workspace.name} ` : ' '}Dashboard`);
          }
          setIsLoading(false);
        });
        
        
      });
    if (workspace.id !== '-1') {
      return onWorkspaceInvites(workspace.id, _invites => {
        setInvites(_invites);
      });
    }
  }, [location.key, workspace.id]);

  const userAccessTab = 'Collaborators';
  const usageTab = 'Usage & Billing';
  const settingsTab = 'Settings';

  const dashboardName = `${workspace && workspace.name ? `${workspace.name} ` : ' '}Dashboard`
  const tabs = [dashboardName, userAccessTab, usageTab, settingsTab]

  const [selectedTab, setSelectedTab] = useState(dashboardName);

  const drawerWidth = 240;
  const minContentWidth = 400;

  return (<Page hideTitle style={{position: 'relative', }}>
    <div style={{marginLeft: drawerWidth + 20, minWidth: minContentWidth / 2,}}>

      {selectedTab === userAccessTab && (<IconButton onClick={() => { setInviteDialogOpen(true);}} disableRipple sx={{ display: 'inline-block', position: 'absolute', top: '1em', right: '2em', zIndex: 99}}>
        <AddRounded sx={{height: '44pt', width: '44pt', }}></AddRounded>
      </IconButton>)}
      <Typography variant='h3' sx={{width: '100%'}}>
        {selectedTab}
      </Typography>
    </div>
    
    <Drawer
        sx={{
          width: drawerWidth,
          position: 'relative',
          marginRight: 'auto',
          flexShrink: 0,
          '& .MuiBackdrop-root': {
            display: "none"
          },
          '& .MuiDrawer-paper': {
            position: 'absolute',
            width: drawerWidth,
            boxSizing: 'border-box',
            transition: 'none !important',
            height: 400,
            border: 0,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          {tabs.map((text, index) => {
            const _isDashboard = text.endsWith('Dashboard') && selectedTab.endsWith('Dashboard');
            const _isSelected = selectedTab === text || _isDashboard;
            return (
              <ListItem sx={{ marginBottom: '1em', borderRadius: 30, backgroundColor: _isSelected ? '#eee' : '#fefefe'}} button key={text} onClick={() => { setSelectedTab(text)}}>
                <ListItemIcon>
                  
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>

      <div style={{marginLeft: drawerWidth + 20, minWidth: minContentWidth}}>
        {!isLoading && selectedTab.endsWith('Dashboard') && (<Dashboard workspace={workspace}/>)}
        {!isLoading && selectedTab === userAccessTab && (
          <Collaborators
            workspace={workspace}
            invites={invites}
            inviteDialogOpen={inviteDialogOpen}
            setInviteDialogOpen={setInviteDialogOpen}
            />
        )}
        {!isLoading && selectedTab === usageTab && (
          <Usage workspace={workspace}/>
        )}
        {!isLoading && selectedTab === settingsTab && (
          <Settings workspace={workspace}/>
        )}
      </div>
  </Page>);
}
