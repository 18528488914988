import { doc, serverTimestamp, setDoc } from '@firebase/firestore';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { database } from '../../utils/firebase';


export default function TermsDialog(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [acceptTermsChecked, setAcceptTermsChecked] = useState(false);
  const [acceptPrivacyChecked, setAcceptPrivacyChecked] = useState(false);

  function acceptTerms() {
    if (!props.uid) {
      return;
    }
    return setDoc(doc(database(), 'user_profiles', props.uid), {
      termsAccepted: 1,
      timestamp: serverTimestamp(),
    }, {merge: true});
  }
  
  function handleTermsCheckedChange(e) {
    setAcceptTermsChecked(e.target.checked);
  }

  function handlePrivacyCheckedChange(e) {
    setAcceptPrivacyChecked(e.target.checked);
  }

  function _acceptTerms() {
    console.log('Clicked!')

    acceptTerms()
      .then(() => {})
      .catch(err => console.log(err))
      .then(() => {});

  }

  const theme = useTheme();
  return <Dialog PaperProps={{
    
    style: {
      minHeight: 440,
      borderRadius: 40,
      textAlign: 'center'

    }
  }} onClose={() => {}} open={props.open ? props.open : false}>
    <DialogTitle
      style={{
        fontFamily: theme.typography.fontFamily,
        fontSize: 28,
        color: theme.palette.background.darkest,
        marginRight: '1em',
        marginLeft: '1em',
      }}>
      Your Privacy Matters
    </DialogTitle>
    <DialogContent sx={{paddingBottom: 0}}>
      <Typography>We do our very best to protect your data and will not sell your personal data to third party.</Typography>
      <br/>
      <Typography>You can access, correct or delete all your data at any time by contacting us at <a href='mailto:privacy@multitoot.com'>privacy@multitoot.com</a></Typography>
      <br/>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Checkbox
          disableRipple
          checked={acceptTermsChecked}
          onChange={handleTermsCheckedChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography align='left' sx={{width: '80%', display: 'inline-block'}}>
          <span>I agree to the <a href='/terms.html'>Terms of Service</a></span>
        </Typography>
      </div>
      <br/>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Checkbox
          disableRipple
          checked={acceptPrivacyChecked}
          onChange={handlePrivacyCheckedChange}
          inputProps={{ 'aria-label': 'controlled' }}/>
        <Typography align='left' sx={{width: '80%', display: 'inline-block'}}>
          <span>I grant permission for my data to be used as described in the <a href='/privacy.html'>Privacy Policy</a></span>
        </Typography>
      </div>
      
    </DialogContent>
    
    
    <DialogActions sx={{paddingTop: 0, paddingLeft: '1em', paddingRight: '1em',}}>
      <Button sx={{
          height: 48,
          borderRadius: 24,
          marginTop: '2em',
          marginBottom: '1em',
          
        }} disabled={isLoading || !acceptPrivacyChecked || !acceptTermsChecked} fullWidth variant='contained' onClick={() => { _acceptTerms(); }}>Continue</Button>
    </DialogActions>
  </Dialog>
}
