import React, { useState } from "react";
import { Card, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useEffect } from "react";
import { useWorkspaces } from "../../contexts/workspacesContext";
import Page from "../Page";
import { Add } from "@mui/icons-material";
import CreateWorkspace from "../CreateWorkspace";
import WorkspaceCard from "../WorkspaceCard";


function Home(props) {
  const theme = useTheme();

  const [workspaces, setWorkspaces] = useState([]);
  const [createWorkSpaceOpen, setCreateWorkSpaceOpen] = useState(false);

  const {onUserWorkspaces} = useWorkspaces();

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  

  useEffect(() => {
    props.setSelectedWorkspace(null);
  }, []);
  

  useEffect(() => {

    return onUserWorkspaces(wsps => {
      props.setMyWorkspaces(wsps);
      setWorkspaces(wsps);
    })
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Page title='Workspaces' backgroundColor={theme.palette.background.main}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: windowDimensions.width > 1100 ? '1fr 1fr 1fr' : '1fr 1fr',
        maxWidth: '1000pt',
        margin: '0 auto'
      }}>
        <Card key='add-workspace' sx={{
          cursor: 'pointer',
          padding: '2em',
          borderRadius: 12,
          minWidth: '200pt',
          maxHeight: '130pt',
          marginRight: '1em',
          marginBottom: '1em',
          "&:hover": {
            opacity: 0.85,
          },
        }} onClick={() => { setCreateWorkSpaceOpen(true) }}>
          <Typography sx={{color: theme.palette.primary.main}} variant='h5'>Add new workspace</Typography>
          <IconButton disabled size='large'>
            <Add sx={{height: 88, width: 88, color: theme.palette.primary.main}}/>
          </IconButton>
        </Card>
        {workspaces.map(w => (
          <WorkspaceCard workspace={w} key={w.id}></WorkspaceCard>))}
      </div>
      
      <CreateWorkspace onClose={() => { setCreateWorkSpaceOpen(false); }} open={createWorkSpaceOpen}></CreateWorkspace>
    </Page>
    
  )
}

export default Home;