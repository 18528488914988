
import { initializeApp } from 'firebase/app';
import { getAuth, updatePassword as firUpdatePassword } from 'firebase/auth';

import { getStorage, ref, uploadBytesResumable, } from '@firebase/storage';
import { getFirestore, collection, getDocs, getDoc, doc } from '@firebase/firestore';
import { dblClick } from '@testing-library/user-event/dist/click';
import { Exposure } from '@mui/icons-material';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER,
  appId: process.env.REACT_APP_APP_ID
};
const app = initializeApp(firebaseConfig);

const db = getFirestore();

export function auth() {
  return getAuth();
}

export function database() {
  return db;
}

export function getCollection(path) {
  return collection(database(), path);
}

export function getDocument(ref) {
  return getDoc(ref);
}

export function getDocuments(ref) {
  return getDocs(ref);
}

export function storageRef(path) {
  return ref(getStorage(), path)
}

export function upload(ref, image) {
  return uploadBytesResumable(ref, image);
}

export function updatePassword(user, password) {
  firUpdatePassword(user, password)
}

export default app;