import './App.css';

import AuthProvider, {} from './contexts/authContext';

import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import TopBar from './components/TopBar/topBar';
import Workspace from './components/Workspace';
import WorkspacesProvider from './contexts/workspacesContext';
import Home from './components/Home';
import { useState } from 'react';

import WebFont from 'webfontloader';
import LoadingDialog from './components/Loader/loadingDialog';
import { useFonts } from './useFonts';

const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const Login = lazy(() => import('./components/Login'));
const PrivateRoute = lazy(() => import('./components/PrivateRoute'));
const Docs = lazy(() => import('./components/Docs/docs'));

WebFont.load({
  google: {
    families: ['ValeraRound', 'Chewy']
  }
});

const theme = createTheme({
  palette: {
    background: {
      light: '#FFEBB6',
      main: '#FED766',
      dark: '#7F6B34',
      darkest: '#40351A',
    },
    primary: {
      light: '#96D0DC',
      main: '#009FB7',
      dark: '#0B4F5C',
      darkest: '#06282D',
    },
    secondary: {
      main: '#009FB7'
    },
    white: {
      main: '#fff'
    }
  },
  typography: {
    fontFamily: [
      'ValeraRound'
    ]
  }
});

function App() {

  const [myWorkspaces, setMyWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState();

  const areFontsLoaded = useFonts('Chewy', 'ValeraRound');
  
  return (
      <div className="App">
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <TopBar myWorkspaces={myWorkspaces} selectedWorkspace={selectedWorkspace} setSelectedWorkspace={setSelectedWorkspace}/>
              <Suspense fallback={<LoadingDialog open={!areFontsLoaded} />}>
                <Routes>
                  <Route exact path='/' element={
                    <PrivateRoute>
                      <WorkspacesProvider>
                        <Home setMyWorkspaces={setMyWorkspaces} setSelectedWorkspace={setSelectedWorkspace}/>
                      </WorkspacesProvider>  
                    </PrivateRoute>} />
                  <Route path='/docs' element={
                    <Docs />
                  }/>
                  <Route exact path='/workspace/:id' element={
                    <PrivateRoute>
                      <WorkspacesProvider>
                        <Workspace setSelectedWorkspace={setSelectedWorkspace} setMyWorkspaces={setMyWorkspaces}/>
                      </WorkspacesProvider>  
                    </PrivateRoute>} />
                  
                  <Route path='/login' element={<Login/>}/>
                  <Route path='/forgot-password' element={<ForgotPassword/>}/>
                </Routes>
              </Suspense>
            </AuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </div>    
  );
}

export default App;
