import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import { useWorkspaces } from "../../contexts/workspacesContext";
import LoadingDialog from "../Loader/loadingDialog";


export default function DeleteUserDialog(props) {

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const {deleteUserFromWorkspace} = useWorkspaces();

  const handleClose = () => {
    props.onClose();
  };

  function _delete() {
    setIsLoading(true);
    deleteUserFromWorkspace(props.workspace.id, props.user)
        .then()
        .catch((err) => console.log('ERROR: ', err))
        .then(() => {
            setIsLoading(false);
            handleClose();
        })
    
    
  }


  return (
    <Dialog PaperProps={{
      style: {
        borderRadius: 40,
        textAlign: 'center'

      }
    }} onClose={handleClose} open={props.open ? props.open : false}>
        <DialogTitle
            style={{
            fontFamily: theme.typography.fontFamily,
            fontSize: 22,
            color: theme.palette.primary.darkest,
            marginRight: '1em',
            marginLeft: '1em',
            }}>
            Delete User
        </DialogTitle>
        <DialogContent>
            Are you sure you wish to delete this user?
        </DialogContent>
      
        <DialogActions sx={{paddingTop: 0, paddingLeft: '1em', paddingRight: '1em',}}>
        
        <Button sx={{
            height: 48,
            borderRadius: 24,
            marginTop: '2em',
            marginBottom: '1em',
            
            }} disabled={isLoading} fullWidth color='primary' variant='outlined' onClick={() => { handleClose(); }}>
            Cancel
        </Button>
        <Button sx={{
            height: 48,
            borderRadius: 24,
            marginTop: '2em',
            marginBottom: '1em',
            
            }} disabled={isLoading} fullWidth color='error' variant='contained' onClick={() => { _delete(); }}>
                Delete
        </Button>
      </DialogActions>
      <LoadingDialog open={isLoading} onClose={() => { setIsLoading(false); }} />
    </Dialog>
  );
}
