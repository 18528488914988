import {
  browserSessionPersistence,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithEmailLink,
} from 'firebase/auth';
import { auth } from './firebase';



const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.

  url: 'http://localhost:3000/',
  // url: 'https://multitoot.com/login',
  // // This must be true.
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
  // dynamicLinkDomain: 'example.page.link'
};

export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const containsEmailRegex = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/;
export function isSignedIn() {
  console.log('USER', auth.currentUser);
  return (!auth.currentUser) === false
}

export function validateEmail(email) {
  if (emailRegex.test(email)) {
    return true
  }
  return false
}

export async function sendSignInLink(email) {
  const _auth = auth();
  return new Promise((resolve, reject) => {
    setPersistence(_auth, browserSessionPersistence)
      .then(() => {
        sendSignInLinkToEmail(_auth, email, actionCodeSettings)
          .then(() => {
            // setting email for sign => Use this to check email
            // upon return to app (from link)
            window.localStorage.setItem('emailForSignIn', email);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }).catch((error) => {
        reject(error);
      });
    
  });
}

export async function doubleCheckLink() {
  const _auth = auth();
  return new Promise((resolve, reject) => {
    setPersistence(_auth, browserSessionPersistence)
      .then(() => {
        // Confirm the link is a sign-in with email link.
        if (isSignInWithEmailLink(_auth, window.location.href)) {
          // Additional state parameters can also be passed via URL.
          // This can be used to continue the user's intended action before triggering
          // the sign-in operation.
          // Get the email if available. This should be available if the user completes
          // the flow on the same device where they started it.
          let email = window.localStorage.getItem('emailForSignIn');
          if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('You are opening a login link from a different browser, please re-enter your email so we can verify this is you');
          }
          // The client SDK will parse the code from the link for you.
          signInWithEmailLink(_auth, email, window.location.href)
            .then((result) => {
              console.log('Login result: ', result);
              
              // Clear email from storage.
              window.localStorage.removeItem('emailForSignIn');
              // You can access the new user via result.user
              // Additional user info profile not available via:
              // result.additionalUserInfo.profile == null
              // You can check if the user is new or existing:
              // result.additionalUserInfo.isNewUser
              
              resolve(result.user);
            })
            .catch((error) => {
              // Some error occurred, you can inspect the code: error.code
              // Common errors could be invalid email and invalid or expired OTPs.
              reject(error);
            });
        } else {
          reject(new Error('Not an email link'));
        }
      }).catch(err => reject(err));    
  });
}

