import { getIdToken, } from 'firebase/auth';
import React, { useContext } from 'react';
import { database, storageRef, upload } from '../utils/firebase';
import { useAuth } from './authContext';

import { getDoc, getDocs, doc, query, where, collection, onSnapshot, addDoc, setDoc, serverTimestamp, deleteDoc, limit, updateDoc } from '@firebase/firestore';
import { ref } from '@firebase/storage';

function randomString(length, chars) {
  if (!chars) {
    throw new Error('Argument \'chars\' is undefined');
  }

  

  const charsLength = chars.length;
  if (charsLength > 256) {
    throw new Error('Argument \'chars\' should not have more than 256 characters'
      + ', otherwise unpredictability will be broken');
  }

  let result = new Array(length);

  for (let i = 0; i < length; i++) {
    const min = 0;
    const max = length;
    const rand = parseInt(Math.random() * (max - min) + min);
    result[i] = chars[rand];
  }

  return result.join('');
}

/** Sync */
function randomAsciiString(length) {
  return randomString(length,
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789');
}

function generateAPIKey() {
  return randomAsciiString(32);
}


const WorkspacesContext = React.createContext();

export function useWorkspaces() {
  return useContext(WorkspacesContext);
}

export default function WorkspacesProvider({ children }) {

  const { currentUser, getUserProfile } = useAuth();

  function getWorkspaceImageURL(workspaceID) {
    return `https://firebasestorage.googleapis.com/v0/b/multitoot-71ca4.appspot.com/o/workspace_images%2F${workspaceID}?alt=media`;
  }

  async function acceptInvite(wid) {
    
    if (!currentUser) {
      console.log('Cannot accept invite if not logged in');
      return;
    }

    const idToken = await getIdToken(currentUser);
    const inviteToken = localStorage.getItem('inviteToken');

    if (!inviteToken) {
      console.error('Trying to accept invite without invite token')
      return;
    }

    fetch(`https://europe-west1-multitoot-71ca4.cloudfunctions.net/acceptInvite?wid=${wid}`, {
      method: 'POST',
      body: JSON.stringify({
        inviteToken,
        email: localStorage.getItem('inviteEmail') || '',
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      }
    }).then(function(response) {
      return response.json();
    }).then((data) => {
      localStorage.removeItem('inviteToken');
      localStorage.removeItem('inviteEmail');
      localStorage.removeItem('inviteWorkspace');
      if (data.error) {
        console.error(data.error);
      } else if (data.success) {
        console.log(data.success);
      } else {
        console.log(`Unexpected behavior accepting token => ${data}`);
      }
    });
  }

  function onWorkspaceKeys(wid, callback) {
    const q = collection(database(), `workspaces/${wid}/keys`);
    return onSnapshot(q, async (querySnapshot) => {
      let _keys = [];
      querySnapshot.forEach(async (doc) => {
        const data = doc.data();
        if (!data.revoked) {
          _keys.push(doc.id);
        }
      });
      callback(_keys);
    });
  }
  function onUserWorkspaces(callback) {
    const q = query(collection(database(), 'workspaces'), where(`roles.${currentUser.uid}`, "==", 'admin'));
    return onSnapshot(q, async (querySnapshot) => {
      let _workspaces = [];
      querySnapshot.forEach(async (doc) => {
        const data = doc.data();

        const userProfilePromises = {};
        for (const userID of Object.keys(data.roles)) {
          userProfilePromises[userID] = getUserProfile(userID);
        }

        _workspaces.push({
          id: doc.id,
          userProfilePromises,
          ...data,
        });
      });

      for (const w of _workspaces) {
        const userProfiles = {};
        
        w.avatarURL = getWorkspaceImageURL(w.id);

        for (const key of Object.keys(w.userProfilePromises)) {
          try {
            const profileImageURL = await w.userProfilePromises[key];
            userProfiles[key] = profileImageURL;
          } catch (_) {
            userProfiles[key] = null;
          }
  
        }
        w.userProfiles = userProfiles;
      }

      callback(_workspaces)
    });
  }

  function onWorkspaceInvites(wid, callback) {
    const q = collection(database(), `workspaces/${wid}/invites`);
    return onSnapshot(q, async (querySnapshot) => {
      let _invites = [];
      querySnapshot.forEach(async (doc) => {
        _invites.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      callback(_invites);
    });
  }

  function uploadWorkspaceImage(wid, image, onProgress) {
    const ref = storageRef(`workspace_images/${wid}`)
    const uploadTask = upload(ref, image);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          onProgress && onProgress(progress);
        },
        error => {
          console.error(error);
          reject(error);
        },
        () => {
          resolve(getWorkspaceImageURL(wid));
        }
      );
    });
  }

  function createWorkspace(name, image, onProgress) {
    const colRef = collection(database(), 'workspaces');
    const roles = {};
    roles[currentUser.uid] = 'admin'
    return addDoc(colRef, {
      name,
      roles,
      timestamp: serverTimestamp(),
    }).then((snap) => {
      const refID = snap.id;
      return uploadWorkspaceImage(refID, image, onProgress);
    });
  }

  function updateWorkspaceName(wid, name) {
    const _doc = doc(database(), `workspaces/${wid}`);
    return updateDoc(_doc, {
      name,
      timestamp: serverTimestamp(),
    });
  }

  function addAPIKey(workspaceID) {
    const apiKey = generateAPIKey();
    return setDoc(doc(database(), `workspaces/${workspaceID}/keys/${apiKey}`), {revoked: false, timestamp: serverTimestamp()});
  }

  function sendWorkspaceInvite(workspaceID, email) {
    return setDoc(doc(database(), `workspaces/${workspaceID}/invites/${email}`), {email,});
  }

  function resendWorkspaceUserInvite(workspaceID, workspaceUser) {
    console.log('USER', workspaceUser);
    console.log('wid', workspaceID);
    const ref = doc(database(), `workspaces/${workspaceID}/users/${workspaceUser.id}`);
    console.log('MADE IT')
    return deleteDoc(ref)
      .then(() => {
        console.log('MADE IT 2', workspaceUser);
        inviteWorkspaceUser(workspaceID, workspaceUser.email, workspaceUser.id);
      }); 
  }

  function onWorkspaceUsers(wid, page, usersPerPage, callback) {
    const q = collection(database(), `workspaces/${wid}/users`, limit((page * usersPerPage) + usersPerPage));
    return onSnapshot(q, async (querySnapshot) => {
      let _users = [];
      querySnapshot.forEach(async (doc) => {
        _users.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      callback(_users);
    });
  }

  function getWorkspaceUsers(wid, search, page, usersPerPage, callback) {
    const q1 = collection(database(), `workspaces/${wid}/users`);
    const q2 = query(q1, where('email', '>=', search));
    const q =  query(q2, where('email', '<=', search + '\uf8ff'));
    console.log(search);
    const _query = query(q, limit((page * usersPerPage) + usersPerPage));
    return getDocs(_query).then((querySnapshot) => { 
      const  _users = querySnapshot.docs.map(u => ({
        id: u.id,
        ...u.data(),
      }));
      callback(_users);
    });
  }

  function getWorkspaceCounters(wid) {
    return getDoc(doc(database(), `workspace_statistics/${wid}`)).then((snap) => {
      return snap.data();
    })
    
  }

  function inviteWorkspaceUser(wid, email, idOrNull) {
    console.log('ID or null', idOrNull);
    if (idOrNull) {
      console.log('MADE IT 3')
      return setDoc(
        doc(database(), `workspaces/${wid}/users/${idOrNull}`),
        {
          email,
          status: 'invited',
          timestamp: serverTimestamp(),
        }
      );
    }
    const colRef = collection(database(), `workspaces/${wid}/users`);
    return addDoc(colRef, {
      email,
      status: 'invited',
      timestamp: serverTimestamp(),
    });
  }

  function inviteMultipleWorkspaceUsers(wid, emails) {
    let promises = [];
    console.log('INVITING EMAILS', emails);
    emails.forEach(email => {
      const colRef = collection(database(), `workspaces/${wid}/users`);
      promises.push(addDoc(colRef, {
        email,
        status: 'invited',
        timestamp: serverTimestamp(),
      }));
    });

    return Promise.all(promises);
  }

  async function deleteUserFromWorkspace(wid, user) {
    const ref = doc(database(), `workspaces/${wid}/users/${user.id}`);
    console.log('MADE IT')
    await deleteDoc(ref)
      
    const ref2 = doc(database(), 'user_profiles', user.connectedUser.id, 'user_workspaces', wid);
    await deleteDoc(ref2);
  }

  function _sendMultitoot(wid, payload) {
    const query = collection(database(), `workspaces/${wid}/users`);
    getDocs(query)
      .then(snap => {
        snap.docs.forEach(doc => {
          if (!doc.exists()) { return; }
          const _data = doc.data();
          if (!_data || !_data.connectedUser || !_data.connectedUser.id) {
            return;
          }
          sendToot(_data.connectedUser.id, wid, false, payload);
        });
      });
    return Promise.resolve();
  }

  function sendToot(userID, workspaceID, isMultitoot, payload) {
    if (isMultitoot) {
      return _sendMultitoot(workspaceID, payload);
    }
    const colRef = collection(database(), `workspaces/${workspaceID}/user_channels/${userID}/messages`);
    return addDoc(colRef, {
      ...payload,
      timestamp: serverTimestamp(),
    });
  }

  const value = {
    updateWorkspaceName,
    uploadWorkspaceImage,
    getWorkspaceImageURL,
    onWorkspaceKeys,
    addAPIKey,
    onUserWorkspaces,
    createWorkspace,
    inviteWorkspaceUser,
    inviteMultipleWorkspaceUsers,
    acceptInvite,
    onWorkspaceInvites,
    sendWorkspaceInvite,
    resendWorkspaceUserInvite,
    onWorkspaceUsers,
    getWorkspaceUsers,
    getWorkspaceCounters,
    sendToot,
    deleteUserFromWorkspace,
  };
  return (<WorkspacesContext.Provider value={value}>
    {children}
  </WorkspacesContext.Provider>);
}