import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import { useWorkspaces } from "../../contexts/workspacesContext";
import { validateEmail } from "../../utils/authUtils";


export default function InviteWorkspaceUser(props) {

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailInputError, setEmailInputError] = useState('');

  const theme = useTheme();

  const {inviteWorkspaceUser} = useWorkspaces();

  const handleClose = () => {
    props.onClose();
  };

  function invite() {
    if (email.length === 0 || !validateEmail(email)) {
      setEmailInputError('Please provide a valid email');
      return;
    }

    setIsLoading(true);

    inviteWorkspaceUser(props.workspace.id, email)
      .then(() => {
        props.onClose();
        props.onSuccess();
      }).catch(err => {
        console.error(err);
        setEmailInputError('Unknown error occurred: Could not send invite')
      })
      .then(() => {
        setIsLoading(false);
      });
    
  }

  return (
    <Dialog PaperProps={{
      style: {
        borderRadius: 40,
        textAlign: 'center'

      }
    }} onClose={handleClose} open={props.open ? props.open : false}>
      <DialogTitle
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: 22,
          color: theme.palette.primary.darkest,
          marginRight: '1em',
          marginLeft: '1em',
        }}>
        Invite users to receive notifications
      </DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <TextField
          sx={{
            marginTop: '1em',
          }}
          InputProps={{
            sx: {
              borderRadius: 24,
              backgroundColor: '#eee'
            },
          }}
          fullWidth
          variant='outlined'
          label={'Email to send invite to'}
          helperText={emailInputError}
          error={emailInputError !== ''}
          onChange={e => setEmail(e.target.value)}
          value={email}
          type='text'></TextField>
      </DialogContent>
      
      
      <DialogActions sx={{paddingTop: 0, paddingLeft: '1em', paddingRight: '1em',}}>
      <Button sx={{
          height: 48,
          borderRadius: 24,
          marginTop: '2em',
          marginBottom: '1em',
          
        }} disabled={isLoading} fullWidth variant='contained' onClick={() => { invite(); }}>Invite user</Button>
      </DialogActions>
    </Dialog>
  );
}
