import React, { useState } from 'react';
import Highlight, { defaultProps } from "prism-react-renderer";
import dracula from 'prism-react-renderer/themes/dracula';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import { colors, Grid, Paper, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy } from '@mui/icons-material';
import { styled, useTheme } from '@mui/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
}));

function replaceFirstX(text, replacement, prefixLength) {
  let realPrefixLength = prefixLength;
  if (prefixLength > text || prefixLength < 0) {
    realPrefixLength = text.length;
  }
  const replacementText = replacement.repeat(realPrefixLength);
  return replacementText + text.slice(realPrefixLength);
}

export default function CodeSample(props) {

  const [copiedText, setCopiedText] = useState('');
  const [selectedLang, setSelectedLang] = useState('bash');

  const languageOptions = [
    'bash',
    'js',
    'py',
  ];

  const langTitles = {
    bash: 'cURL',
    js: 'Node.js',
    py: 'Python',
  }

  const apiKeyText = props.apiKey || '$API_KEY';
  const workspaceIDText = props.workspaceID || '$WORKSPACE_ID';

  const examples = {
    toot: {
      bash: `
curl --request POST 'https://multitoot.com/api/toot' \\
  --header 'api_key: ${apiKeyText}' \\
  --header 'workspace_id: ${workspaceIDText}' \\
  --header 'Content-Type: application/json' \\
  --data-raw '{
      "userID": "'"$USER_ID"'",
      "type": "title_message",
      "title": "Toot!",
      "message": "You have a new message"
  }'
      `,
      js: `
const headers = {
  'Content-Type': 'application/json',
  'workspace_id': '${props.workspaceID || '$WORKSPACE_ID'}',
  'api_key': '${props.apiKey || '$API_KEY'}'
};

const body = {
  userID: $USER_ID,
  type: 'title_message', // title_message | url_action
  title: 'Toot!',
  message: 'You have a new message',
  // url: 'https://www.google.com' // needed for type === url_action
};

fetch(
  'https://multitoot.com/api/toot',
  {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  }
);
      `,
      py: `
import requests

url = 'https://multitoot.com/api/toot'
body = {
  'userID': $USER_ID,
  'type': 'title_message', # title_message | url_action
  'title': 'Toot!',
  'message': 'You have a new message',
  # 'url': 'https://www.google.com' # needed for type === url_action
}

headers = {
  'Content-Type': 'application/json',
  'workspace_id': '${workspaceIDText}',
  'api_key': '${apiKeyText}'
}

response = requests.post(url, data = body)

      `
    },
    invite: {
      bash: `
curl --request POST 'https://multitoot.com/api/invite' \\
  --header 'api_key: ${apiKeyText}' \\
  --header 'workspace_id: ${workspaceIDText}' \\
  --header 'Content-Type: application/json' \\
  --data-raw '{
      "email": "test@test.com"
  }'
            `,
      js: `
const headers = {
  'Content-Type': 'application/json',
  'workspace_id': '${workspaceIDText}',
  'api_key': '${apiKeyText}'
};

fetch(
  'https://multitoot.com/api/invite',
  {
    method: 'POST',
    headers,
    body: JSON.stringify({
      email: 'test@test.com'
    })
  }
).then(res => res.json())
.then(data => console.log(data.userID)); // save the userID to your db for sending Toots(!) later
      `,
      py: `
import requests

url = 'https://multitoot.com/api/invite'
body = {'email': 'test@test.com'}

headers = {
  'Content-Type': 'application/json',
  'workspace_id': '${workspaceIDText}',
  'api_key': '${apiKeyText}'
}

response = requests.post(url, data = body)

print(response.json().userID) # save the userID to your db for sending Toots(!) later
      `
    }
  };

  const theme = useTheme();
  console.log('selectedLang', selectedLang);

  const exampleCode = examples[props.variant][selectedLang];

  let exampleCodeMasked = exampleCode;
  if (props.apiKey) {
    exampleCodeMasked = exampleCode.replace(props.apiKey, replaceFirstX(props.apiKey, '•', props.apiKey.length - 4));
  }
  
  return (
    <div>
      <Grid container spacing={2} sx={{marginBottom: '0.5em'}}>
        {languageOptions.map(option => {
          console.log(option);
          console.log('IS OPTION', option === selectedLang);
          return (
            <Grid key={option} item xs={4}>
             <Item
              sx={{
                cursor: 'pointer',
                backgroundColor: option === selectedLang ? theme.palette.primary.main : colors.grey[100],
              }}
              onClick={() => { setSelectedLang(option) }}>
                <span style={{color: (option === selectedLang ? 'white' : theme.palette.primary.darkest)}}>{langTitles[option]}</span>
              
             </Item>
           </Grid>
          );
        })}
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        component={Box}>
        <CopyToClipboard
          text={exampleCode}
          onCopy={() => { setCopiedText(exampleCode)}}
        >
          <Tooltip
            title={
              copiedText === exampleCode
                ? "This was Copied!"
                : "Copy To Clipboard"
            }
            placement="top"
            >
              <Box
                sx={{borderRadius: 4, cursor: 'pointer'}}
                component="button"
                fontFamily="inherit"
                fontSize="16px"
                fontWeight="400"
                lineHeight="1.25"
                display="inline-block"
                width="100%"
                margin=".5rem 0"
                padding="0px"
                textAlign="left"
                color={colors.grey[800]}
                border="0"
                borderRadius="4px"
                // className={classes.button}
                data-clipboard-text="album-2"
                type="button"
              >
                <div style={{position: 'relative'}}>
                  
                <div style={{paddingLeft: '1em', backgroundColor: 'rgb(40, 42, 54)', borderRadius: '12pt', overflow: 'hidden'}}>
                  <Highlight {...defaultProps} code={exampleCodeMasked} language={selectedLang} theme={dracula}>
                    
                    {({ className, style, tokens, getLineProps, getTokenProps }) => (
                      <pre className={className} style={style}>
                        {tokens.map((line, i) => (
                          <div {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                              <span {...getTokenProps({ token, key })} />
                            ))}
                          </div>
                        ))}
                      </pre>
                    )}
                  </Highlight>
                </div>
                  <ContentCopy color='white' sx={{position: 'absolute', top: 20, right: 20}}/>
                </div>
              </Box>
          </Tooltip>
        </CopyToClipboard>
      </Grid>
    </div>
  );
}
