import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useState } from "react";
import { useWorkspaces } from "../../contexts/workspacesContext";
import LoadingDialog from "../Loader/loadingDialog";


export default function CreateTootDialog(props) {

  const [isLoading, setIsLoading] = useState(false);

  const [type, setType] = useState('title_message');

  const [title, setTitle] = useState('');
  const [titleInputError, setTitleInputError] = useState('');

  const [message, setMessage] = useState('');
  const [messageInputError, setMessageInputError] = useState('');

  const [url, setURL] = useState(null);
  const [urlInputError, setUrlInputError] = useState('');

  const theme = useTheme();

  const {sendToot} = useWorkspaces();

  function _clearErrors() {
    setMessageInputError('');
    setTitleInputError('');
    setUrlInputError('');
  }

  function _clear() {
    setMessage(null);
    setTitle('');
    setURL(null);
    setType('title_message');
    
    _clearErrors();
  }

  const handleClose = () => {
    props.onClose();
    _clear();
  };

  function _sendToot(userID, isMultitoot, payload) {
    console.log('ATTEMPT TO SEND VIA WSP', props.workspace.id);
    if (!props.workspace.id) {
      return
    }

    setIsLoading(true);
    const prevNow = new Date();
    return sendToot(userID, props.workspace.id, isMultitoot, payload)
      .then(() => {})
      .catch((_) => {})
      .then(() => {
        // fake 1 second loading for functions to kick in
        const requestDuration = (new Date()).getTime() - prevNow.getTime();
        let waitTime = 0;
        if (requestDuration < 1000) {
          waitTime = 1000 - requestDuration;
        }
        setTimeout(() => setIsLoading(false), waitTime);
      });
  }

  function send() {
    _clearErrors();
    if (title.length <= 0) {
      setTitleInputError('Please provide a title');
      return;
    }

    let urlIsValid = false;
    try {
      const _ = new URL(url);
      urlIsValid = true; 
    } catch (_) {}

    if (type === 'url_action' && (url.length <= 0 || !urlIsValid)) {
      setUrlInputError('Please provide a valid URL');
      return;
    }

    // if (message.length <= 0) {
    //   setMessageInputError('Please provide a message');
    //   return;
    // }

    // TODO: send message
    console.log('USER ID', props.userID);
    _sendToot(
      props.userID,
      props.isMultitoot || false,
      {
        title,
        message,
        type,
        url,
      }
    );
    handleClose();
    
  }

  const typeOptions = [
    {id: 'title_message', name: 'Standard'},
    {id: 'url_action', name: 'URL action'},
  ];

  function handleSelectedTypeChanged(event) {
    const {
      target: { value },
    } = event;
    console.log(event);
    
    
    const found = typeOptions.find(o => o.id === value);
    setType(found.id);
  }

  const MenuProps = {
    PaperProps: {
      style: {
        borderRadius: '20pt',
        maxHeight: '200pt',
      },
    },
  };


  return (
    <Dialog PaperProps={{
      style: {
        borderRadius: 40,
        textAlign: 'center'

      }
    }} onClose={handleClose} open={props.open ? props.open : false}>
      <DialogTitle
        style={{
          fontFamily: theme.typography.fontFamily,
          fontSize: 22,
          color: theme.palette.primary.darkest,
          marginRight: '1em',
          marginLeft: '1em',
        }}>
        Create a new Toot
      </DialogTitle>
      <DialogContent sx={{paddingBottom: 0}}>
        <Select
          sx={{
            marginTop: '2px',
            borderRadius: 22,
            height: 44,
            paddingLeft: '0.5em',
            paddingRight: '0.5em',
          }}

          displayEmpty
          value={type}
          onChange={handleSelectedTypeChanged}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {typeOptions.map(option => (
            <MenuItem value={option.id} key={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        <TextField
          sx={{
            marginTop: '1em',
          }}
          InputProps={{
            sx: {
              borderRadius: 24,
              backgroundColor: '#eee'
            },
          }}
          fullWidth
          variant='outlined'
          label={'Title'}
          helperText={titleInputError}
          error={titleInputError !== ''}
          onChange={e => setTitle(e.target.value)}
          value={title}
          type='text'/>

        {type === 'title_message' && (<TextField
          sx={{
            marginTop: '1em',
          }}
          InputProps={{
            sx: {
              borderRadius: 24,
              backgroundColor: '#eee'
            },
          }}
          fullWidth
          variant='outlined'
          label={'Message (optional)'}
          helperText={messageInputError}
          error={messageInputError !== ''}
          onChange={e => setMessage(e.target.value)}
          value={message}
          type='text'/>)}

        {type === 'url_action' && (<TextField
          sx={{
            marginTop: '1em',
          }}
          InputProps={{
            sx: {
              borderRadius: 24,
              backgroundColor: '#eee'
            },
          }}
          fullWidth
          variant='outlined'
          label={'URL to open on click'}
          helperText={urlInputError}
          error={urlInputError !== ''}
          onChange={e => setURL(e.target.value.length > 0 ? e.target.value : null)}
          value={url}
          type='text'/>)}
      </DialogContent>
      
      
      <DialogActions sx={{paddingTop: 0, paddingLeft: '1em', paddingRight: '1em',}}>
      <Button sx={{
          height: 48,
          borderRadius: 24,
          marginTop: '2em',
          marginBottom: '1em',
          
        }} disabled={isLoading} fullWidth variant='contained' onClick={() => { send(); }}>Send</Button>
      </DialogActions>
      <LoadingDialog open={isLoading} onClose={() => { setIsLoading(false); }} />
    </Dialog>
  );
}
