import { MenuItem, OutlinedInput, Paper, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useWorkspaces } from '../../contexts/workspacesContext';
import PricingCard from '../LandingPage/pricingCard';
import PaymentDialog from '../PaymentDialog/paymentDialog';
/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
 function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];

  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}

function getMonth(idx) {

  var objDate = new Date();
  objDate.setDate(1);
  objDate.setMonth(idx-1);

  var locale = "en-us",
      month = objDate.toLocaleString(locale, { month: "long" });

  return month;
}


export default function Usage(props) {
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [_notificationCounters, setNotificationCounters] = useState({});
  const now = new Date();
  const initialYear = now.getFullYear();
  const initialMonth = now.getMonth() + 1;
  const initialSelectedMonth = `${initialYear}_${initialMonth}`;
  const [selectedMonth, setSelectedMonth] = useState(initialSelectedMonth);

  const {getWorkspaceCounters} = useWorkspaces();

  let notificationCounters = Object.assign({}, _notificationCounters);
  if (selectedMonth === initialSelectedMonth && !notificationCounters[initialSelectedMonth]) {
    notificationCounters = {
      ..._notificationCounters,

    };
  }

  useEffect(() => {
    getWorkspaceCounters(props.workspace.id).then(counters => {
      setNotificationCounters(counters.notificationCounters);
      
    })
  }, [props.workspace.id]);

  const monthYearTuple = selectedMonth.split('_').reverse();
  const days = getDaysInMonth(parseInt(monthYearTuple[0]) - 1, monthYearTuple[1]);

  const workingNotificationCounters = notificationCounters[selectedMonth] || {};

  for (const day in days) {
    console.log(day);
    if (!workingNotificationCounters[day]) {
      workingNotificationCounters[day] = 0;
    }
  }


  const notificationsChartData = Object.keys(workingNotificationCounters)
    .map(day => {
      return {
        dayOfMonth: parseInt(day) + 1,
        Toots: workingNotificationCounters[day],
      }
    });
  const tootsSent = Object.values(workingNotificationCounters)
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
  let tootsSentString;
  if (tootsSent > 1) {
    tootsSentString = (<span>{tootsSent}<br/>Toots sent</span>);
  } else if (tootsSent <= 0) {
    tootsSentString = (<span>No sent Toots</span>);
  } else {
    tootsSentString = (<span>1<br/>Toot sent</span>);
  }

  console.log('COUNTERS', notificationCounters);
  const monthOptions = Object.keys(notificationCounters).sort().map(key => {
    const [year, month] = key.split('_');

    return {
      id: key,
      name: `${getMonth(month)} ${year}`,
    }
  });

  if (!monthOptions.find(el => el.id === initialSelectedMonth)) {
    monthOptions.push({
      id: initialSelectedMonth,
      name: `${getMonth(initialMonth)} ${initialYear}`,
    })
  }

  const theme = useTheme();

  const MenuProps = {
    PaperProps: {
      style: {
        borderRadius: '20pt',
        maxHeight: '200pt',
      },
    },
  };

  function handleSelectedMonthChanged(event) {
    const {
      target: { value },
    } = event;
    console.log(event);
    
    
    const found = monthOptions.find(o => o.id === value);
    setSelectedMonth(found.id);
  }

        
  const selectedPlan = 'Starter';

  return (<div style={{padding: '2em'}}>
    <PaymentDialog variant='Professional' open={paymentDialogOpen} onClose={() => setPaymentDialogOpen(false)} />
    <Paper sx={{ position: 'relative', borderRadius: 10, padding: '2em', paddingTop: '1em',}}>
      <Typography sx={{marginBottom: '2em', marginTop: '0.5em'}} variant='h4' textAlign='left'>Toots sent</Typography>
      <Select
          sx={{
            marginTop: '2px',
            borderRadius: 22,
            height: 44,
            paddingLeft: '0.5em',
            paddingRight: '0.5em',
          }}

          displayEmpty
          value={selectedMonth}
          onChange={handleSelectedMonthChanged}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {monthOptions.map(option => (
            <MenuItem value={option.id} key={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      <div style={{
        // display: 'grid',
        // gridGap: '10px',
        // gridTemplateColumns: '7fr 3fr',

      }}>
        <div style={{position: 'relative', display: 'inline-block', width: '180px',}}>
          <div style={{position: 'relative', height: 240, width: 180, margin: 'auto', marginBottom: 30, marginRight: '1em'}}>
            <div style={{
              position: 'absolute',
              bottom: 30,
              left: 0,
              width: 170,
              maxWidth: 170,
              height: 170,
              aspectRatio: 1,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '50%',
            }}>
              <Typography style={{
                color: 'white',
                textAlign: 'center',
                fontSize: 20,
                width: '100%',
                margin: 0,
                position: 'absolute',
                top: '50%',
                '-ms-transform': 'translateY(-50%)',
                transform: 'translateY(-50%)',
              }}>
                {tootsSentString}
              </Typography>
            </div>
          </div>
        </div>
        <div style={{display: 'inline-block', position: 'relative', width: 'calc(100% - 200px)', height: 240,}}>
          <ResponsiveContainer width='100%' height={240}>
            <BarChart data={notificationsChartData}
                height={240}>
              <CartesianGrid strokeDasharray='1 1' />
              <XAxis dataKey='dayOfMonth'/>
              <YAxis allowDecimals={false}/>
              <Tooltip />
              <Legend/>
              <Bar dataKey='Toots' fill={theme.palette.primary.main}/>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div style={{marginTop: '2em'}}>
        <Typography sx={{marginBottom: '2em', marginTop: '0.5em'}} variant='h4' align='left'>Billing Plan</Typography>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          maxWidth: '1000pt',
          margin: '0 auto'
        }}>
          <PricingCard onClick={() => {
            console.log('Cancel subscription');
          }} variant='Starter' alignment='left' selected={selectedPlan === 'Starter'} />
          <PricingCard comingSoon onClick={() => {
            setPaymentDialogOpen(true);
          }} variant='Professional' alignment='left' selected={selectedPlan === 'Professional'}/>
        </div>
      </div>

      
    </Paper>    
  </div>);
}