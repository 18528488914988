import React, { useState } from 'react';
import DropIn from 'braintree-web-drop-in-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useAuth } from '../../contexts/authContext';

export default function PaymentDialog(props) {

  const [isLoading, setIsLoading] = useState(true);
  // const [submitInProgress, setSubmitInProgress] = useState(false);
  const [instance, setInstance] = useState(null);

  const handleClose = (success) => {
    props.onClose(success);
    cleanup();
  };

  const cleanup = () => {
    // setSubmitInProgress(false);
  };

  const theme = useTheme();

  function submit() {

    setIsLoading(true);
    // setSubmitInProgress(true);

    instance.requestPaymentMethod({}, (error, payload) => {
      if (error) {
        console.error('whoops 1', error);
        setIsLoading(false);
        return;
      }

      fetch('https://europe-west1-multitoot-71ca4.cloudfunctions.net/subscriptionCheckout',
        {
          body: JSON.stringify({
            paymentMethodNonce: payload.nonce,
            userID: 'h2UGZkW9ZNSP99EeLl348eny44l1', // currentUser.id,
            email: 'thorvald@multitoot.com', // currentUser.email,
            planID: props.variant.toLowerCase(),
            workspaceID: props.workspace.id,
          }),
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'POST'
        }
      ).then(res => res.json())
      .catch(err => {
        console.error('whoops 2', err);
        setIsLoading(false);
        handleClose(false);
      })
      .then(jsonData => {
        setIsLoading(false);
        if (jsonData.result === 'success') {
          handleClose(true); 
        } else {
          handleClose(false);
        }
      });
    });

  };

  return (<Dialog

    onClose={(event, reason) => {
      if (!isLoading && reason === 'backdropClick') {
        handleClose(false);
      }
    }}

    PaperProps={{
      style: {
        padding: '1em',
        borderRadius: 40,
        textAlign: 'center'
      }
    }}
    open={props.open}
  >
    <DialogTitle
      style={{
        fontFamily: theme.typography.fontFamily,
        fontSize: 28,
        color: theme.palette.background.darkest,
        marginRight: '1em',
        marginLeft: '1em',
      }}>
      {`${props.variant || ''} Subscription`}
    </DialogTitle>
    <DialogContent sx={{position: 'relative', paddingBottom: 0}}>
      <div style={isLoading ? {pointerEvents: 'none', opacity: 0.5} : {}}>
        <DropIn
          options={{ authorization: 'sandbox_rzrxgvv8_2t34c3vzxzdyyxv7'}}
          onInstance={(_instance) => {
            setIsLoading(false);
            console.log('LOADING FINISHED');
            setInstance(_instance);
          }}


        
          onNoPaymentMethodRequestable={() => {
            handleClose(false);
          }}

          onPaymentOptionSelected={(payload) => {
            console.log('SELECTED', payload);
          }}
        />
      </div>
      
      
      {isLoading && <div
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <i
          className='fa fa-circle-notch fa-spin'
          style={{
            WebkitAnimation: 'fa-spin 0.75s infinite linear',
            animation: 'fa-spin 0.75s infinite linear',
            top: '50%',
            height: 40,
            width: 40,
            msTransform: 'translateY(-50%)',
            transform: 'translateY(-50%)',
            fontSize: 40,
            
          }}
        />
      </div>}
    </DialogContent>
    
    
    <DialogActions sx={{paddingTop: 0, paddingLeft: '1em', paddingRight: '1em',}}>
      {!isLoading && <Button
        sx={{
          height: 48,
          borderRadius: 24,
          marginTop: '2em',
          marginBottom: '1em',  
        }} disabled={isLoading} fullWidth variant='contained' onClick={() => submit()}>Submit</Button>}
    </DialogActions>
  </Dialog>);
}
