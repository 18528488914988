import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

export default function Page(props) {

  const theme = useTheme();
  const minPortalWidth = 900;

  const muiAppBarDefaultHeight = '64px';

  return (<div style={{padding: '2em', minWidth: minPortalWidth,}}>
    {!props.hideTitle && (<Typography variant='h2' color={theme.palette.primary.darkest}>{props.title && props.title}</Typography>)}
    <div style={{
      left: 0,
      right: 0,
      minWidth: minPortalWidth,
      position: 'absolute',
      top: props.hideTitle ? '100px' : '200px', /* Header Height */
      height: 'fit-content',
      minHeight: props.hideTitle ? `max(100% - 100px - ${muiAppBarDefaultHeight}, 500px)` : `max(100% - 200px - ${muiAppBarDefaultHeight}, 500px)`,
      borderTopLeftRadius: '60px',
      borderTopRightRadius: '60px',
      padding: '2em',
      // borderBottomLeftRadius: '60px',
      // borderBottomRightRadius: '60px',
      backgroundColor: props.backgroundColor || '#fefefe',
      
    }}>{props.children}</div>
  </div>)
}
