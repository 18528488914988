import { LocationCityRounded, SavingsRounded, SkateboardingRounded, WorkRounded } from '@mui/icons-material';
import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

export default function PricingCard(props) {

  const theme = useTheme();

  const colorMap = {
    'Professional': 'white'
  };

  const textColor = colorMap[props.variant] || theme.palette.primary.darkest;
  console.log(props.variant, textColor);
  const backgroundColorMap = {
    'Professional': theme.palette.primary.main
  };

  const iconColorMap = {
    'Professional': 'orange',
  };

  const iconColor = iconColorMap[props.variant] || theme.palette.primary.main;

  const iconVariantMap = {
    'Starter': <SavingsRounded sx={{width: 40, height: 40, color: iconColor}}/>,
    'Hobby': <SkateboardingRounded sx={{ width: 40, height: 40, color: iconColor}}/>,
    'Professional': <WorkRounded sx={{width: 40, height: 40, color: iconColor}}/>,
    'Enterprise': <LocationCityRounded sx={{width: 40, height: 40, color: iconColor}}/>,
  }

  const featureVariantMap = {
    'Starter': [
      'Send toots! (notifications) with the Multitoot dashboard and/or API',
      'Register users with the Multitoot dashboard and/or API',
      (<strong>100 toots/day</strong>),
      'Basic customer support',
    ],
    'Hobby': [
      'Register up to a 10,000 users',
      'Send notifications through the Multitoot portal',
      'Send notifications via the Multitoot API',
      'Register users via the Multitoot API',
      '10,000 toots/day',
    ],
    'Professional': [
      'Register unlimited users',
      'Send toots! (notifications) through the Multitoot portal and/or API',
      'Register users with the Multitoot dashboard and/or API',
      (<strong>50,000 toots/day</strong>),
      'Premium customer support'
    ],
    'Enterprise': [
      'Register unlimited users',

      'Send notifications through the Multitoot portal',
      'Send notifications via the Multitoot API',
      'Unlimited messages/month',
      'Professional Dashboard',
    ],
  }

  const pricingVariantMap = {
    'Starter': <Typography
      style={{color: textColor}}
      sx={{
      marginTop: '0.5em',
      marginBottom: '0.5em',
    }} variant='h6'>FREE</Typography>,
    'Hobby': <Typography sx={{
      color: textColor,
      marginTop: '0.5em',
      marginBottom: '0.5em',
    }} variant='h6'>$10/month</Typography>,
    'Professional': <Typography
      style={{color: textColor}}
      sx={{
        marginTop: '0.5em',
        marginBottom: '0.5em',
      }} variant='h6'>$30/month</Typography>,
    'Enterprise': <Button sx={{
      fontFamily: 'ValeraRound',
      fontWeight: 800,
        height: 44,
        borderRadius: 22,
        marginTop: '1em',
        marginBottom: '1em',
      }} variant='contained'
      href={`mailto:sales@multitoot.com`}>Get in touch
    </Button>,
  }

  const icon = iconVariantMap[props.variant];
  const features = featureVariantMap[props.variant];
  const pricing = pricingVariantMap[props.variant];

  const alignment = props.alignment || 'left';

  // const borderColor = {
  //   'Starter': theme.palette.primary.main,
  //   'Professional': theme.palette.primary.main,
  // }

  const isComingSoon = props.comingSoon === true;

  return (
    <Card
      onClick={!isComingSoon ? () => { props.onClick && props.onClick() } : null}
      sx={{
        position: 'relative',
        '&:hover': (!props.selected && !isComingSoon) ? {
          opacity: 0.5,
        } : {},
        opacity: isComingSoon ? 0.5 : 1,
        backgroundColor: backgroundColorMap[props.variant],
        // boxShadow: 'none',
        color: textColor,
        borderRadius: 12,
        padding: '1em',
        paddingTop: '3em',
        minWidth: '150pt', 
        // minHeight: '300pt',           
        marginRight: '1.5em',
        marginBottom: '1em',
        cursor: !isComingSoon ? 'pointer' : 'auto',
      }}>
    
    {isComingSoon && <Typography variant='h4' align='center' sx={{
      position: 'absolute',
      textAlign: 'center',
      width: '100%',
      top: '50%',
      transform: 'translate(0, -50%)',
      msTransform: 'translate(0, -50%)',
      zIndex: 2,
    }}>
      Coming Soon  
    </Typography>}
    <CardMedia align='center' sx={{paddingBottom: '1em'}}>
      {icon}      
    </CardMedia>

    <Typography style={{color: textColor}} variant='h5' align='center'>
      {props.variant}
      <br/>
      {props.selected ? '(Selected)' : ''}
    </Typography>
    <Typography style={{color: textColor}} variant='h6' align='center'>{pricing}</Typography>
    
    <CardContent>
      
      <ul
        style={{
          listStyle: 'none',

          width: alignment === 'center' ? '60%': null,
          margin: '0 auto' 
        }}>
        {features.map(feature => {
          return (
            <li style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '0.5em',

              padding: '2px'
            }} key={feature}>
              <Typography style={{marginRight: '8px', top:  0, color: textColor, display: 'inline-block', width: '14px', height: '20px'}}>● &nbsp;&nbsp;&nbsp;</Typography>
              <Typography style={{color: textColor, display: 'inline-block', width: 'calc(100% - 24px)'}} sx={{textAlign: 'left', fontSize: 14}} variant='body2'>
                {feature}
              </Typography>              
            </li>
          )
        })}
      </ul>
      
    </CardContent>
  </Card>
  )
}