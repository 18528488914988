import { AddRounded, Delete, RemoveCircle } from '@mui/icons-material';
import { Button, colors, IconButton, Paper, Snackbar, SnackbarContent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { color } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useWorkspaces } from '../../contexts/workspacesContext';
import LoadingDialog from '../Loader/loadingDialog';
import CreateTootDialog from './createTootDialog';
import DeleteUserDialog from './deleteUserDialog';
import InviteMultiWorkspaceUsers from './inviteMultiWorkspaceUsers';
import InviteWorkspaceUser from './inviteWorkspaceUser';

let _dashboardDebounceTimeout;
function debounce(callback, limit) {
  
                     
  return function () {                     
    if (_dashboardDebounceTimeout) {
      clearTimeout(_dashboardDebounceTimeout);
    }
    _dashboardDebounceTimeout = setTimeout(function () {
      callback();
    }, limit);
    
  }
}

export default function Dashboard(props) {

  const [resultText, setResultText] = useState('');
  const [isDoingStuff, setIsDoingStuff] = useState(false);

  const [inviteWorkspaceUserOpen, setInviteWorkspaceUserOpen] = useState(false);
  const [inviteMultiWorkspaceUsersOpen, setInviteMultiWorkspaceUsersOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [workspaceCounters, setWorkspaceCounters] = useState({endUserCount: 0})
  const rowsPerPage = 20; 

  const [search, setSearch] = useState('');

  const [workspaceUsers, setWorkspaceUsers] = useState([]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const [messageFormID, setMessageFormID] = useState();
  const [messageFormOpen, setMessageFormOpen] = useState(false);

  const [multitootFormOpen, setMultitootFormOpen] = useState(false);

  const [userToDelete, setUserToDelete] = useState();
  const [showDeleteUserPopUp, setShowDeleteUserPopUp] = useState(false);

  const { getWorkspaceUsers, getWorkspaceCounters, resendWorkspaceUserInvite, } = useWorkspaces()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getWorkspaceUsers(props.workspace.id, search, newPage, rowsPerPage, setWorkspaceUsers);
  };

  function refresh(skipCounter, skipLoader) {
    
    if (!skipCounter) {
      getWorkspaceCounters(props.workspace.id)
        .then(setWorkspaceCounters)
    }

    if (!skipLoader) {
      setIsDoingStuff(true);
    }
    
    const prevNow = new Date();
    getWorkspaceUsers(props.workspace.id, search, page, rowsPerPage, setWorkspaceUsers)
      .then(() => {
        let waitTime = 0;
        const requestDuration = (new Date()).getTime() - prevNow.getTime();
        if (requestDuration < 500) {
          waitTime = 500 - requestDuration;
        }
        setTimeout(() => {
          if (!skipLoader) {
            setIsDoingStuff(false)
          }
        }, waitTime)
      });    
  }

  const refreshSearch = debounce(() => {
    console.log('calling refresh');
    setResultText(`Results for "${search}"`);
    refresh(true, true);
  }, 600);

  useEffect(() => {
    if (search.length === 0) {
      refresh(false);
      return;
    }
    refreshSearch();
  }, [search, props.workspace])


  const theme = useTheme();

  const snackBarAutoHideDuration = 4000;

  let totalCount = workspaceCounters ? (workspaceCounters.endUserCount ?? 0) : 0;
  let labelRowsPerPageLabel = null;
  if (search.length > 0) {
    totalCount = -1;
    labelRowsPerPageLabel = '...';
  }

  return (<div style={{padding: '2em', paddingLeft: '1em', paddingTop: '4em', paddingRight: '3em',}}>
    <Snackbar
      open={snackBarOpen}
      autoHideDuration={snackBarAutoHideDuration}
      onClose={(event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSnackBarOpen(false);
      }}
      // action={...}
    >
      <SnackbarContent
        sx={{
          borderRadius: 13,
          backgroundColor: colors.green['A700'],
          color: 'white',
        }}
        message={'Successfully sent invite'}
      />
    </Snackbar>

    
    <Paper sx={{ borderRadius: 10, padding: '2em', overflow: 'hidden', position: 'relative'}}>
      <Typography variant='h4' sx={{textAlign: 'left', flex: 5}}>Users</Typography>
      {totalCount > 0 && (
        <Button sx={{position: 'absolute', top: '20pt', right: '10pt', display: 'inline-block', borderRadius: 22,}} variant='contained' disableRipple onClick={() => setMultitootFormOpen(true)}>Send Multitoot</Button>  
      )}
      <br/>
      <TextField
        sx={{margin: '0 auto', marginBottom: '1em',}}
        InputProps={{
          sx: {
            borderRadius: 24,
            backgroundColor: '#eee'
          },
        }}
        fullWidth
        variant='outlined'
        label={'Search'}
        onChange={e => {
          setSearch(e.target.value);
        }}
        value={search}
        type='text'/>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell sx={{textAlign: 'left'}}>Email</TableCell>
              <TableCell sx={{textAlign: 'center'}}>Status</TableCell>
              <TableCell align='right'>
                <Button onClick={() => setInviteMultiWorkspaceUsersOpen(true)} startIcon={<AddRounded />}>
                  Add users
                </Button>
                {/* <IconButton onClick={() => setInviteWorkspaceUserOpen(true)} disableRipple sx={{ display: 'inline-block', zIndex: 99,}}>
                  <AddRounded sx={{height: '24pt', width: '24pt',}}/>
                </IconButton>
                <IconButton onClick={() => setInviteWorkspaceUserOpen(true)} disableRipple sx={{ display: 'inline-block', zIndex: 99,}}>
                  <AddRounded sx={{height: '24pt', width: '24pt',}}/>
                </IconButton> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {workspaceUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((workspaceUser, i) => (
              <TableRow
                
                key={`Key_${i}`}
                sx={{height: 40, position: 'relative', '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{padding: '8pt', fontSize: 14}} component="th" scope="row">
                  {workspaceUser.status === 'accepted' && (<IconButton disableFocusRipple disableRipple color='error' sx={{
                        marginRight: '1em',
                        borderRadius: 15,
                        maxWidth: 120,
                        fontSize: 10,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      fullWidth
                      variant='contained'
                      disabled={snackBarOpen}
                      onClick={() => {
                        if (!workspaceUser.connectedUser || !workspaceUser.connectedUser.id) {
                          console.log(workspaceUser);
                          return
                        }
                        setUserToDelete(workspaceUser);
                        setShowDeleteUserPopUp(true);
                      }}>
                      <RemoveCircle />
                    </IconButton>)}
                  {workspaceUser.email}
                </TableCell>
                <TableCell sx={{padding: '8pt', position: 'relative'}} align="right">
                  {workspaceUser.status === 'invited' && (<div style={{
                    margin: '0 auto',
                    backgroundColor: theme.palette.background.light,
                    color: theme.palette.background.darkest,
                    borderRadius: 15,
                    height: 30,
                    textAlign: 'center',
                    fontSize: 14,
                    maxWidth: 120,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    {workspaceUser.status}
                  </div>)}
                  {workspaceUser.status === 'accepted' && (<div style={{
                    margin: '0 auto',
                    backgroundColor: colors.green['A400'],
                    color: colors.green[1000],
                    borderRadius: 15,
                    height: 30,
                    textAlign: 'center',
                    fontSize: 14,
                    maxWidth: 120,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    {workspaceUser.status}
                  </div>)}
                  
                </TableCell>
                <TableCell sx={{padding: '8pt', }} component='th' scope='row' align='right'>
                  {workspaceUser.status !== 'accepted' && (<Button sx={{
                      borderRadius: 15,
                      maxWidth: 120,
                      fontSize: 12,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    fullWidth
                    variant='contained'
                    disabled={snackBarOpen}
                    onClick={() => {
                      resendWorkspaceUserInvite(props.workspace.id, workspaceUser)
                        .then(() => {
                          setSnackBarOpen(true);
                        });
                    }}>
                    Resend invite
                  </Button>)}

                  {workspaceUser.status === 'accepted' && (<Button sx={{
                      borderRadius: 15,
                      maxWidth: 120,
                      fontSize: 10,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                    fullWidth
                    variant='contained'
                    disabled={snackBarOpen}
                    onClick={() => {
                      if (!workspaceUser.connectedUser || !workspaceUser.connectedUser.id) {
                        console.log(workspaceUser);
                        return
                      }
                      setMessageFormID(workspaceUser.connectedUser.id);
                      setMessageFormOpen(true);
                    }}>
                    Send Toot!
                  </Button>)}
                  
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelDisplayedRows={search.length > 0 ? ({from, to, count}) => {
          if (resultText.length <= 0) {
            return '';
          }
          return `${resultText} - Page ${parseInt(to) / rowsPerPage}`;
        } : undefined}
        rowsPerPageOptions={[rowsPerPage]}
        labelRowsPerPage={labelRowsPerPageLabel}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
    <InviteWorkspaceUser
      workspace={props.workspace}
      onClose={() => { setInviteWorkspaceUserOpen(false); }}
      onSuccess={() => {
        setWorkspaceCounters({
          endUserCount: (workspaceCounters?.endUserCount || 0) + 1,
        });
        refresh(true);        
      }}
      open={inviteWorkspaceUserOpen}
    />
    <InviteMultiWorkspaceUsers
      open={inviteMultiWorkspaceUsersOpen}
      workspace={props.workspace}
      onClose={() => { setInviteMultiWorkspaceUsersOpen(false); }}
      onSuccess={() => {
        
        refresh(true);        
      }}
      
    />
    <LoadingDialog open={isDoingStuff} onClose={() => { setIsDoingStuff(false); }} />
    <CreateTootDialog
      open={messageFormOpen}
      userID={messageFormID}
      workspace={props.workspace}
      onClose={() => {
        setMessageFormID();
        setMessageFormOpen(false);
      }} />

    <CreateTootDialog
      open={multitootFormOpen}
      isMultitoot
      userID={null}
      workspace={props.workspace}
      onClose={() => {
        setMessageFormID(null);
        setMultitootFormOpen(false);
      }} />
    
    <DeleteUserDialog
      open={showDeleteUserPopUp}
      user={userToDelete}
      workspace={props.workspace}
      onClose={() => {
        setUserToDelete();
        setShowDeleteUserPopUp(false);
        refresh(true);
      }} />
  </div>);
}
