import React, { useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// @material-ui/core components

// core components
import { ContentCopy } from "@mui/icons-material";
import { colors, Grid, Tooltip } from "@mui/material";
import { Box } from "@mui/system";

export default function CopyToClipboardText(props) {
  const text = props.text;
  
  const [copiedText, setCopiedText] = useState();

  function replaceFirstX(text, replacement, prefixLength) {
    let realPrefixLength = prefixLength;
    if (prefixLength > text || prefixLength < 0) {
      realPrefixLength = text.length;
    }
    const replacementText = replacement.repeat(realPrefixLength);
    return replacementText + text.slice(realPrefixLength);
  }

  return (
    <div>
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        component={Box}>
        <CopyToClipboard
          text={text}
          onCopy={() => setCopiedText(text)}
        >
          <Tooltip
            title={
              copiedText === text
                ? "This was Copied!"
                : "Copy To Clipboard"
            }
            placement="top"
          >
            <Box
              sx={{borderRadius: 4, cursor: 'pointer'}}
              component="button"
              fontFamily="inherit"
              fontSize="16px"
              fontWeight="400"
              lineHeight="1.25"
              display="inline-block"
              width="100%"
              margin=".5rem 0"
              padding="24px"
              textAlign="left"
              color={colors.grey[800]}
              border="0"
              borderRadius="4px"
              // className={classes.button}
              data-clipboard-text="album-2"
              type="button"
            >
              <div style={{position: 'relative'}}>
                
                <span>
                  <strong>{props.title && (`${props.title}:  `)}</strong>
                  {props.maskText ? replaceFirstX(text, '•', text.length - 4) : text}
                </span>
                <ContentCopy sx={{position: 'absolute', top: 0, right: 0}}/>
              </div>
            </Box>
          </Tooltip>
        </CopyToClipboard>
      </Grid>
    </div>
  );
};
