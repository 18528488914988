import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { useWorkspaces } from '../../contexts/workspacesContext';
import { validateEmail } from '../../utils/authUtils';

export default function InviteDialog(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailInputError, setEmailInputError] = useState('');

  const theme = useTheme();

  const { sendWorkspaceInvite } = useWorkspaces();

  function handleClose() {
    props.onClose();
  }

  function sendInvite() {
    if (!validateEmail(email)) {
      setEmailInputError('Please provide a valid email');
      return;
    }

    setIsLoading(true);

    sendWorkspaceInvite(props.workspace.id, email)
      .then(() => {
        handleClose();
      })
      .catch(err => {
        console.error(err);
        setEmailInputError('Unable to send invite to email');
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  return (<Dialog PaperProps={{
    style: {
      borderRadius: 40,
      textAlign: 'center'

    }
  }} onClose={handleClose} open={props.open ? props.open : false}>
    <DialogTitle
      style={{
        marginRight: '1em',
        marginLeft: '1em',
        fontFamily: theme.typography.fontFamily,
        fontSize: 28,
        color: theme.palette.primary.darkest,

      }}>
      Send invite to a collaborator
    </DialogTitle>
    <DialogContent sx={{paddingBottom: 0}}>
      <TextField
        sx={{
          marginTop: '1em',
        }}
        InputProps={{
          sx: {
            borderRadius: 24,
            backgroundColor: '#eee'
          },
        }}
        fullWidth
        variant='outlined'
        label={'Email to send invite to'}
        helperText={emailInputError}
        error={emailInputError !== ''}
        onChange={e => setEmail(e.target.value)}
        value={email}
        type='text'></TextField>
    </DialogContent>
    
    
    <DialogActions sx={{ paddingTop: 0, paddingLeft: '1em', paddingRight: '1em',}}>
    <Button sx={{
        height: 48,
        borderRadius: 24,
        marginTop: '2em',
        marginBottom: '1em',
        
      }} disabled={isLoading} fullWidth variant='contained' onClick={() => { sendInvite(); }}>Send invite</Button>
    </DialogActions>
  </Dialog>);
}
